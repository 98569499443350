<template>
  <section class="container">
    <div class="text">
      <h2>Create and collect virtually any type of fees and deposits</h2>
      <p>Setup from move-in fees to deposits in a few clicks with the ability to add any amount you need to collect. Our platform will automatically refund any deposit for you without any worries.</p>
      <ZButton link="/request">Request a Demo</ZButton>
    </div>
    <div class="image">
      <img src="@/assets/img/mobile-action.png" alt="mobile actions" />
    </div>
  </section>
</template>

<script>
import ZButton from '@/components/ZButton.vue';

export default {
  name: 'Container4',
  components: {
    ZButton,
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: black;
  @include M {
    margin-left: 0;
  }
}
.container {
  background-color: $white;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas:
  "image"
  "text";
  color: $black;
  @include M {
    grid-template-rows: auto;
    grid-template-columns: 50% 50%;
    grid-template-areas: "text image";
  }
  .text {
    grid-area: text;
    margin: auto 0;
    font-size: 2rem;
    line-height: 3rem;
    padding: 10rem 2rem;
    text-align: center;
    @include M {
      text-align: left;
    }
    @include XL {
      padding-left: 20rem;
    }
    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      @include M {
        font-size: 3.5rem;
        line-height: 4rem;
      }
      @include M {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    }
    p {
      margin: 2rem 0;
    }
  }

  .image {
    grid-area: image;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: $purple-darkest;
    padding-top: 2rem;
    img {
      height: auto;
      width: 80%;
      display: block;
      margin: auto;
      margin-bottom: 0;
      @include M {
        height: auto;
      }
    }
  }
}
</style>
