<template>
<section class="container">
    <div class="title">
      <img src="@/assets/img/smartsecure.png" alt="smart secure">
      <h2>
        The Next Generation of<br>
        <b>deposit and fee management for the real estate world</b>
      </h2>
      <div class="button">
        <ZButton link="/request">Request a Demo</ZButton>
      </div>
    </div>
    <div class="image">
      <img src="@/assets/img/phone.png" alt="phone image">
    </div>
  </section>
</template>

<script>
import ZButton from '@/components/ZButton.vue';

export default {
  name: 'Container1',
  components: {
    ZButton,
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  background-image: linear-gradient(#765aa2, #3e3780, #482d89);
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-areas:
  "title"
  "image";
  padding: 10rem $Gpadding 0 $Gpadding;
  color: $white;
  @include M {
    padding: 20rem 5rem 0 5rem;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    grid-template-areas:
    "title image";
  }
  @include L {
    padding: 20rem 10rem 0 10rem;
  }
  .title {
    grid-area: title;
    h2 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 100;
      @include M {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    img {
      width: 70%;
      margin-bottom: 2rem;
      max-width: 30rem;
    }
    @include M {
      img, h2 {
        margin-top: 2rem;
        margin-bottom: 8rem;
      }
    }
    .button {
      grid-area: button;
      width: 100%;
      margin: 6rem auto;
      @include M {
        margin: 0;
        margin-bottom: auto;
      }
    }
  }

  .image {
    grid-area: image;
    text-align: center;
    margin: auto;
    margin-top: auto;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    @include M {
      height: auto;
    }
    img {
      height: auto;
      width: 100%;
      max-height: 100%;
      @include M {
        width: 100%;
        height: auto;
        max-height: none;
      }
      @include XL {
        width: 80%;
      }
    }
  }
}
</style>
