<template>
  <section class="container">
    <div class="categories">
      <CategoryPlus class="categoryplus" v-for="item in categories" :key="item">{{ item }}</CategoryPlus>
    </div>
  </section>
</template>

<script>
import CategoryPlus from '@/components/CategoryPlus.vue';

export default {
  name: 'Container3',
  components: {
    CategoryPlus,
  },
  setup() {
    const categories = [
      'Smart Contracts',
      'Deposit Management',
      'Fee Management',
      'Instant Deposit Returns',
      'No Software to Download',
      'No integration Required',
      'Instant Usability',
      'Automated and Live Support',
    ];

    return {
      categories,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: $Gpadding $Gpadding 10rem $Gpadding;
  background-color: $gray-lightest;
  width: 100%;
  .categories {
    display: grid;
    grid-template-columns: 100%;
    gap: 2rem;
    margin: 0 auto;
    @include M {
      margin-left: 0;
      width: 100%;
      grid-template-columns: 49% 49%;
    }
    @include XL {
      .categoryplus {
        margin-right: 2rem;
        &:nth-child(even) {
          margin-left: 2rem;
        }
      }
    }
  }
}
</style>
