<template>
<section class="container">
    <div class="image">
      <img src="@/assets/img/Macbook.png" alt="laptop" />
    </div>
    <div class="text">
      <h2>A unique and convenient platform for Condominium and Multifamily property financial management.</h2>
      <p> Collect payments, deposits and fees in a totally secure, flexible environment without having to download or integrate software. Zinc easily partners with existing property management platforms or can be used as a standalone solution.</p>
      <p>Zinc’s smart contract based solution manages payments, deposits, returns, fees and more with near instant usability and round the clock automated support.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Container2',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  background-color: $gray-lightest;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas:
  "text"
  "image";
  gap: 2rem;
  color: $black;
  padding: $Gpadding $Gpadding 10rem $Gpadding;
  @include M {
    padding: 10rem 5rem 10rem 5rem;
    grid-template-rows: auto;
    grid-template-columns: 50% 50%;
    grid-template-areas: "image text";
  }
  @include L {
    padding: 10rem 10rem 0 10rem;
  }
  .text {
    grid-area: text;
    margin: auto 0;
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 0 2rem;
    @include M {
      font-size: 2rem;
      line-height: 3.2rem;
      padding: 0 2rem;
    }
    @include L {
      padding: 0 8rem;
    }
    @include XL {
      padding: 0 20%;
    }
    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      @include M {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
    p {
      margin-top: 2rem;
    }
  }

  .image {
    grid-area: image;
    text-align: center;
    background-image: url('../../assets/img/z-big.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: center;
    img {
      height: auto;
      width: auto;
      max-height: 30rem;
      max-width: 90%;
      display: block;
      margin: auto 0;
      @include M {
        width: 90%;
        height: auto;
        max-height: none;
      }
    }
  }
}
</style>
