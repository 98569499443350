<template>
  <div class="conditions">
    <div class="icon">
      <img class="img-icon" :src="require(`@/assets/img/conditions/${icon}`)" alt="button plus">
      <img class="img-icon-plus" src="@/assets/img/icon-plus.svg" alt="button plus">
    </div>
    <div class="text">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Conditions',
  props: {
    icon: { type: String, required: true },
  },
  components: {
  },
};
</script>

<style lang="scss" scoped>
.conditions {
  width: 100%;
  max-width: 17rem;
  .icon {
    height: 17rem;
    background-color: $white;
    border-radius: 2rem;
    padding: 6rem;
    position: relative;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    .img-icon {
      width: 4.5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .img-icon-plus {
      width: 4rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2rem;
    }
  }
  .text {
    padding-top: 3rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
</style>
