<template>
  <div class="category">
    <div class="text"><slot></slot></div>
    <div class="img"><img src="@/assets/img/buttonPlus.svg" alt="button plus"></div>
  </div>
</template>

<script>

export default {
  name: 'CategoryPlus',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.category {
  padding: 1rem 3rem;
  display: flex;
  width: 100%;
  max-width: 50rem;
  background-color: $white;
  border-radius: 1rem;
  margin: 0 auto;
  .text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 5.1rem;
    width: 85%;
    @include M {
      font-size: 2rem;
    }
  }
  .img {
    width: 15%;
    text-align: right;
    height: 90%;
    margin: auto 0;
    max-height: 7rem;
    line-height: 4.5rem;
    img {
      height: 4rem;
      width: auto;
    }
  }
}
</style>
