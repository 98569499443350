<template>
<section class="container">
    <div class="text">
      <img src="@/assets/img/z-gray.svg" alt="small logo">
      <h2>You set the conditions<br>Zinc will take care of the rest</h2>
    </div>
    <div class="conditions">
      <Conditions v-for="item in conditions" :key="item" :icon="item.icon">{{item.text}}</Conditions>
    </div>
  </section>
</template>

<script>
import Conditions from '@/components/Conditions.vue';

export default {
  name: 'Container5',
  components: {
    Conditions,
  },
  setup() {
    const conditions = [
      { text: 'Link property bank accounts', icon: 'icon-bank.svg' },
      { text: 'Collect fees and deposits', icon: 'icon-coin-hand.svg' },
      { text: 'Automated refunds', icon: 'icon-mobile-dollar.svg' },
      { text: 'Manage property contracts', icon: 'icon-contract.svg' },
      { text: 'Report incidents on any deposit', icon: 'icon-incident.svg' },
      { text: 'Track tenant payment activity', icon: 'icon-clipboard.svg' },
    ];

    return { conditions };
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $gray-lightest;
  display: grid;
  grid-template-rows: autos;
  grid-template-areas:
  "text"
  "conditions";
  gap: 2rem;
  color: $black;
  padding: $Gpadding $Gpadding 10rem $Gpadding;
  @include M {
    background-image: url('../../assets/img/bg6.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
  }
  .text {
    grid-area: text;
    font-size: 1.5rem;
    line-height: 3rem;
    padding-left: 4rem;
    padding-bottom: 4rem;
    @include M {
      font-size: 2rem;
      line-height: 3.2rem;
      margin-left: 0;
    }
    h2 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 700;
      @include M {
        font-size: 3.8rem;
        line-height: 6rem;
      }
    }
    img {
      margin-bottom: 2rem;
    }
    @include M {
      padding: 8rem;
    }
    @include L {
        padding: 8rem 10rem;
    }
    @include XL {
      padding: 8rem 15rem;
    }

  }

  .conditions {
    grid-area: conditions;
    width: 100%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    & > div {
      margin: 2rem;
    }
  }
}
</style>
