<template>
  <section class="container">
    <div class="text">
      <h2>Any time, Any place, Anywhere</h2>
      <p>Managing property fees and deposits has never been this easy with a single platform. You can access Zinc anywhere at any time and have visibility of all the activity for your propert</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Container4',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: black;
  margin-top: 8rem;
  @include M {
    margin-left: 0;
  }
}
.container {
  background-color: rgb(231, 221, 212);
  color: $black;
  @include M {
    background-image: url('../../assets/img/girl2.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
  }
  .text {
    grid-area: text;
    margin: auto 0;
    font-size: 2rem;
    line-height: 3rem;
    padding: 10rem 2rem;
    text-align: center;
    @include M {
      text-align: left;
      width: 60%;
    }
    @include XL {
      padding-left: 20rem;
    }
    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      @include M {
        font-size: 3.5rem;
        line-height: 4rem;
      }
      @include M {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    }
    p {
      margin: 4rem 0;
    }
  }
}
</style>
