<template>
  <Container1 />
  <Container2 />
  <Container3 />
  <Container4 />
  <Container5 />
  <Container6 />
</template>

<script>
import Container1 from '@/components/Home/Container1.vue';
import Container2 from '@/components/Home/Container2.vue';
import Container3 from '@/components/Home/Container3.vue';
import Container4 from '@/components/Home/Container4.vue';
import Container5 from '@/components/Home/Container5.vue';
import Container6 from '@/components/Home/Container6.vue';

export default {
  name: 'Home',
  components: {
    Container1,
    Container2,
    Container3,
    Container4,
    Container5,
    Container6,
  },
};
</script>

<style lang="scss" scoped>
</style>
